import $ from 'jquery';
import 'jquery-validation';

$(function () {
  const config = window.ncmodernsignin;

  $('#nc-modern-signin').click(function (e) {
    e.stopPropagation();
  });

  const submitForm = async (e) => {
    e.preventDefault();

    const response = await fetch(config.ajaxUrl, {
      body: new FormData(e.currentTarget),
      method: 'POST',
    });

    if (response.redirected) {
      window.location = response.url;
    }

    const data = await response.json();

    var globalError = data[""];
    $('#nc-modern-signin #global-error').remove();
    if (!globalError) return;
    $('#nc-modern-signin form').prepend('<p id="global-error" class="nc-modern-signin__error nc-modern-signin__error--global">' + globalError[0] + '</p>');
  };

  if (config.dynamicValidation) {
    $('#nc-modern-signin form').attr('novalidate', true);
    $('#nc-modern-signin form').validate({
      rules: {
        password: {
          minlength: 5,
        }
      },
      messages: config.errorMessages,
      submitHandler: function (form, e) {
        return submitForm(e);
      },
      errorPlacement: function (error, element) {
        error.appendTo(element.parents('.col-md-6'));
      },
      highlight: function () {},
      errorElement: 'p',
      errorClass: 'nc-modern-signin__error',
    });
  } else {
    $('#nc-modern-signin form').on('submit', (e) => {
      return submitForm(e);
    });
  }


});
